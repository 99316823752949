import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

export default function Product({ x, tetik,pushed,cart,setCart,counts,setCounts ,selected,setSelected}) {
  const [counter, setCounter] = React.useState(counts.find(y=>y.product_id==x.product_id)?.count||1);

  function incrementCounter() {
    setCounter(counter * 1 + 1);
  }

  function decrementCounter() {
    setCounter(counter * 1 - 1);
  }
  

  function addToCart(){
    if(cart.find(y=>y.product_id==x.product_id)){
      setCart(cart.map(y=>{
        if(y.product_id==x.product_id){
          return {...y,count:counter+y.count}
        }
        return y
      }))
    }else{
      setCart([...cart,{...x,count:counter}])
    }
    
  }

  useEffect(() => {
    if (pushed) {
      addToCart();
    }
  }, [pushed]);
  

  useEffect(() => {
    setCounter(tetik);
  }, [tetik]);

  useEffect(() => {
    if ((counter*1) < 0) {
      setCounter(0);
      return
    }else if((counter+"").split("")[0]==0){
      setCounter(1)
      return
    }else{
      setCounts([
        ...counts.filter((y) => y.product_id != x.product_id),
        { product_id: x.product_id, count: counter },
      ]);
    }
      
    

  }, [counter]);

  return (
    <tr>
      <td>
        <div className="shopping-cart__product-item">
          <a href="#" style={{ position: "relative" }}>
            <img loading="lazy" src={x.image} width="120" height="120" alt="" />
            {cart.find(y=>y.product_id==x.product_id) && <div style={{position:"absolute",top:0,right:0,zIndex:991,backgroundColor:"red",color:"#fff",borderRadius:"50%",paddingLeft:5,paddingRight:5}}>{cart.find(y=>y.product_id==x.product_id).count}</div>}
          </a>
        </div>
      </td>
      <td>
        <div className="shopping-cart__product-item__detail">
          <h4>
            <a href="product1_simple.html">{x.name}</a>
          </h4>
          <ul className="shopping-cart__product-item__options">
            {/* <li>Color: Yellow</li> */}
            {/* <li>Size: L</li> */}
          </ul>
        </div>
      </td>
      <td>
        <span className="shopping-cart__product-price">
          ₺{x.price.sale_price}
        </span>
      </td>
      <td>
        <div className="qty-control position-relative qty-initialized">
          <input
            type="number"
            name="quantity"
            value={counter}
            className="qty-control__number text-center"
            onChange={(e) => setCounter(e.target.value)}
          />
          <div className="qty-control__reduce" onClick={decrementCounter}>
            -
          </div>
          <div className="qty-control__increase" onClick={incrementCounter}>
            +
          </div>
        </div>
      </td>
      <td>
        <span className="shopping-cart__subtotal">₺{x.price.sale_price}</span>
      </td>
      <td style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <a
          style={{ cursor: "pointer" }}
          className="remove-cart"
          onClick={() => addToCart()}
        >
          <FontAwesomeIcon icon={faShoppingCart} color="green" />
        </a>
        <a>
          <input type="checkbox" checked={selected.find(y=>y.product_id==x.product_id)} onChange={e=>{
            if(e.target.checked){
              setSelected([...selected,{product_id:x.product_id}])
            }else{
              setSelected(selected.filter(y=>y.product_id!=x.product_id))
            }
          }} />
        </a>
      </td>
    </tr>
  );
}
