import React,{useState,useEffect} from 'react'
import Product from './Product';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default function ProductList({products,cart,setCart}) {
  const [tetik,setTetik]=useState(0)
  useEffect(() => {
    if (tetik * 1 < 0) {
      setTetik(1);
      return;
    } else if ((tetik + "").split("")[0] == 0) {
      setTetik(1);
      return;
    } else {
      setCounts(
        products.map((x) => {
          return { product_id: x.product_id, count: tetik };
        })
      );
    }
    
  }, [tetik])
  const [pushed,setPushed]=useState(false)
  const [counts,setCounts]=useState([products.map(x=>{
    return { product_id: x.product_id, count: 1 };
  })])
  const push=()=>{
    setCart(products.map(x=>{
      if(selected.filter(y=>y.product_id==x.product_id).length>0){
        return {
          product_id: x.product_id,
          count: cart.find((y) => y.product_id == x.product_id)
            ? cart.find((y) => y.product_id == x.product_id).count * 1 +
              (counts.find((y) => y.product_id == x.product_id)
                ? counts.find((y) => y.product_id == x.product_id)?.count * 1 ||
                  tetik * 1
                : tetik * 1)
            : counts.find((y) => y.product_id == x.product_id)
            ? counts.find((y) => y.product_id == x.product_id)?.count * 1 ||
              tetik * 1
            : tetik * 1,
        };
      }else{
        return {
          product_id: x.product_id,
          count: cart.find((y) => y.product_id == x.product_id)? cart.find((y) => y.product_id == x.product_id).count :1,
        };
      }
      
    }))
    
  }
  
  const [selected, setSelected] = useState([]);
  return (
    <div className="shopping-cart">
      <div className="cart-table__wrapper">
        <div style={{ marginBottom: 30,display: "flex",justifyContent: "space-between" }}>
          <div>
            <span style={{ marginRight: 15, fontSize: 17 }}>Adet</span>
            <input
              type="number"
              value={tetik}
              style={{
                borderRadius: 10,
                border: "2px solid #ddd",
                outline: 0,
                width: 70,
                textAlign: "center",
              }}
              onChange={(e) => setTetik(e.target.value)}
            />
          </div>
          <div style={{background:'darkblue',cursor:'pointer',color:'#fff',borderRadius:10,padding:5,paddingLeft:20,paddingRight:20}} onClick={()=>{
            Swal.fire({
              title: "Toplam Hesaplayıcı",
              confirmButtonText: "Tamam",
              html:

                "Toplam varyant sayısı : " +
                products.length +
                " <br/> Toplam seçilen miktar sayısı : " +
                counts.reduce((total, item) => {
                  // Seçili ürünlerin listesinde bu ürün var mı kontrol edelim
                  const selectedItem = selected.find(
                    (selectedItem) =>
                      selectedItem.product_id === item.product_id
                  );

                  // Eğer seçili ürünler listesinde varsa ve ürünün count değeri 0'dan büyükse, toplama ekle
                  if (selectedItem && item.count > 0) {
                    total += item.count * 1;
                  }

                  return total;
                }, 0),
            });
          }}>TOPLAM</div>
        </div>

        <table className="cart-table">
          <thead>
            <tr>
              <th>Ürün</th>
              <th></th>
              <th>Fiyat</th>
              <th>Adet</th>
              <th>Toplam</th>
              <th>
                <button
                  onClick={() => {
                    if (selected.length == products.length) {
                      setSelected([]);
                    } else {
                      setSelected(products);
                    }
                  }}
                >
                  Tümünü {selected.length == products.length ? "Kaldır" : "Seç"}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {(products != null && products.length > 0 ? products : []).map(
              (x) => {
                return (
                  <Product
                    x={x}
                    tetik={tetik}
                    pushed={pushed}
                    cart={cart}
                    setCart={setCart}
                    counts={counts}
                    selected={selected}
                    setSelected={setSelected}
                    setCounts={setCounts}
                  />
                );
              }
            )}
          </tbody>
        </table>
        <div
          style={{
            width: "100%",
            display: "block",
            position: "relative",
            height: 100,
          }}
        >
          <button
            style={{
              right: 0,
              top: 10,
              position: "absolute",
              padding: "5px 10px 5px 10px",
              backgroundColor: "green",
              color: "white",
              borderRadius: "10px",
              fontSize: 17,
              border: "none",
            }}
            onClick={() => {
              if (selected.length == 0) {
                return;
              }
              Swal.fire({
                title: "Sepete Ekle",
                text:
                  "Toplam " +
                  selected.length +
                  " Farklı ürün (Toplam adet " +
                  counts.reduce((total, item) => {
                    // Seçili ürünlerin listesinde bu ürün var mı kontrol edelim
                    const selectedItem = selected.find(
                      (selectedItem) =>
                        selectedItem.product_id === item.product_id
                    );

                    // Eğer seçili ürünler listesinde varsa ve ürünün count değeri 0'dan büyükse, toplama ekle
                    if (selectedItem && item.count > 0) {
                      total += item.count * 1;
                    }

                    return total;
                  }, 0) +
                  ") sepetinize eklemek üzeresiniz. Sepetinize eklemek istiyor musunuz?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire("Eklendi!", "Ürün sepetinize eklendi.", "success");
                  push(1);
                  // Sepete ekleme işlemini burada gerçekleştirin
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  //Swal.fire("İptal Edildi", "Ürün sepetinize eklenmedi.", "error");
                }
              });
              // push(1)
            }}
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              style={{ marginRight: 15 }}
            />
            Sepete Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
