import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default function Sepet({cart,setCart,products}) {
  return (
    <>
      <hr />
      <div style={{ fontSize: 25, padding: 10 }}>
        <FontAwesomeIcon icon={faShoppingCart} /> Sepetim
      </div>
      <hr />
      <div>
        {(products!=null && products.length >0 ? cart:[]).map((x) => {
          
          const item=products.find(y=>y.product_id==x.product_id)
          
          return (
            <div className="item" style={{ display: "flex",borderBottom:"1px solid #ddd",paddingBottom:10,paddingTop:10 }}>
              <div className="image" style={{ width: "10%" }}>
                <img src={item.image} height={50} />
              </div>
              <div className="name" style={{ width: "60%" }}>
                {item.name}
              </div>
              <div style={{width:'5%'}}> x{x.count}</div>
              <div className="price" style={{ width:'8%',marginLeft: 30 }}>
                {(item.price.sale_price * x.count).toFixed(2)} TL
              </div>

              <div className="action-area" style={{width:'10%'}}>
                <button
                  onClick={() => {
                    setCart(cart.filter((y) => y.product_id != x.product_id));
                  }}
                  style={{
                    marginLeft: 30,
                    background: "#e74c3c",
                    color: "#444",
                    cursor: "pointer",
                    borderRadius: 125,
                    width: 40,
                    marginLeft: 50,
                    color: "white",
                    padding: 8,
                    outline: "none",
                    border: "none",
                    fontWeight: "bold",
                  }}
                >
                  Sil
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
