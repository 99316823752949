import React,{useState,useEffect} from 'react'
import ProductList from './ProductList';
import Sepet from './Sepet';

export default function App() {
  const [products,setProducts]=useState(null)
  useEffect(() => {
    
    if(products==null){
      fetch(
        "https://proxy.eticaret.deha.dev/?url=https://api.mcdodo.com.tr/api/products?size=25"
      ).then(res=>res.json()).then(res=>{
        setProducts(res.products.map(x=>{
          return {...x,count:1}
        }))
      });
    }
  
    
  }, [products])

  const [cart,setCart]=useState(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [])

  useEffect(() => {
    localStorage.setItem("cart",JSON.stringify(cart))
  }, [cart])

  const [tab,setTab]=useState(0)
  
  return (
    <>
      {/* {products!=null ? <ProductList cart={cart} setCart={setCart} products={products}/> : "Loading..."}

      <Sepet cart={cart} setCart={setCart} products={products}  /> */}
      <section className="shop-checkout container">
        <h2 className="page-title">Sepet</h2>
        <div className="checkout-steps">
          <a
            onClick={() => setTab(0)}
            className={"checkout-steps__item " + (tab == 0 ? "active" : "")}
            style={{ cursor: "pointer" }}
          >
            <span className="checkout-steps__item-number">01</span>
            <span className="checkout-steps__item-title">
              <span>Ürün Ekle</span>
              <em>Ürün seç ve sepete ekle </em>
            </span>
          </a>
          <a
            onClick={() => setTab(1)}
            className={"checkout-steps__item" + (tab == 1 ? " active" : "")}
            style={{ cursor: "pointer" }}
          >
            <span className="checkout-steps__item-number">02</span>
            <span className="checkout-steps__item-title">
              <span>Sepeti Görüntüle</span>
              <em>Sepetini görüntüle ve düzenle</em>
            </span>
          </a>
        </div>
        {products != null && tab == 0 ? (
          <ProductList cart={cart} setCart={setCart} products={products} />
        ) : (
          <Sepet cart={cart} setCart={setCart} products={products} />
        )}
      </section>
    </>
  );
}
